import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import NotesIcon from '@material-ui/icons/Notes';
import moment from 'moment';

const styles = theme => ({
  card: {
    width: '17.375rem',
    height: '12.5rem',
    borderRadius: 0,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.modeColors.cardBackground,
  },
  cardBorder: {
    width: '17.125rem',
    height: '12.25',
    border: '2px solid #f46b1e',
    boxShadow: 'none',
  },
  actionArea: {
    marginLeft: '-15px',
    marginRight: '-15px',
    width: 'calc(100% + 30px)',
    '&:hover $focusHighlight': {
      opacity: 1,
    },
  },
  cardMedia: {
    height: '9.75rem',
  },
  focusHighlight: {
    backgroundColor: 'rgba(247, 248, 249, 0.8)',
    height: '9.75rem',
  },
  classStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '0.25rem 1rem 0.125rem',
    color: '#fff',
    backgroundColor: '#ffa632',
  },
  mediaFooter: {
    position: 'absolute',
    top: '8rem',
    right: '1.5rem',
    display: 'flex',
  },
  chipIcon: {
    backgroundColor: '#fff',
    width: '1.25rem',
    height: '1.25rem',
    padding: '0.125rem',
    borderRadius: '50%',
    marginBottom: '0.25rem',
  },
  chipTag: {
    height: '1.5rem',
    backgroundColor: '#fff',
    padding: '0rem 0.5rem 0.125rem',
    marginLeft: '0.375rem',
    marginBottom: '0.25rem',
  },
  tags: {
    position: 'absolute',
    top: '0.75rem',
    left: 0,
    display: 'flex',
    flexWrap: 'wrap',
    zIndex: 2,
  },
});

class LgProposal extends Component {
  state = {
    hover: false,
  };

  onHover = () => {
    this.setState({hover: true});
  };

  onUnHover = () => {
    this.setState({hover: false});
  };

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;

    const {
      classes,
      seen,
      proposal,
      proposalClass,
      proposalPublicTags,
      proposalHasNote,
    } = this.props;
    const {hover} = this.state;
    return (
      <Card
        className={classNames(classes.card, {[classes.cardBorder]: !seen})}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onUnHover}
      >
        <CardActionArea
          classes={{
            root: classes.actionArea,
            focusHighlight: classes.focusHighlight,
          }}
        >
          <CardMedia
            className={classes.cardMedia}
            component="img"
            height="156"
            image={process.env.REACT_APP_URL + proposal.thumbnail_url}
          />
          <CardContent className="py-1 px-3">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="mb-0">{proposal.number}</h2>
              <div className="sm">
                {seen && !readOnly
                  ? `Last seen ${moment(new Date(seen)).format('D.MM.YYYY')}`
                  : readOnly
                  ? ''
                  : 'Unseen'}
              </div>
              {proposalClass && (
                <div
                  className={classes.classStyle}
                  style={{backgroundColor: proposalClass.colour}}
                >
                  {proposalClass.class}
                </div>
              )}
              {!process.env.REACT_APP_READ_ONLY && (
                <>
                  <div className={classes.tags}>
                    {hover && proposalPublicTags
                      ? proposalPublicTags.map((tag, i) => {
                          if (i < 4) {
                            return (
                              <Chip
                                key={tag.id}
                                label={tag.name}
                                classes={{
                                  root: classes.chipTag,
                                  label: 'xs p-0',
                                }}
                              />
                            );
                          }
                          return null;
                        })
                      : null}
                  </div>
                  <div className={classes.mediaFooter}>
                    {proposalHasNote && (
                      <NotesIcon className={classes.chipIcon} fontSize="small" />
                    )}
                    {!hover && proposalPublicTags && (
                      <Chip
                        label={proposalPublicTags.length + ' tags'}
                        classes={{
                          root: classes.chipTag,
                          label: 'sm p-0',
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

LgProposal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LgProposal);
