import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '../inputs/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    backgroundColor: theme.modeColors.inputBackground,
  },
});

class ManageReview extends React.Component {
  state = {
    tag: '',
    editor: EditorState.createEmpty(),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {review} = nextProps;
    let editorDetails;
    if (review) {
      const contentBlock = htmlToDraft(review);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorDetails = EditorState.createWithContent(contentState);
    } else {
      editorDetails = EditorState.createEmpty();
    }
    this.setState({editor: editorDetails});
  }

  onEditorChange = editorState => {
    this.setState({editor: editorState});
  };

  createMarkup(data) {
    return {__html: data};
  }

  onSave = () => {
    const {editor} = this.state;
    const htmlDetails = draftToHtml(convertToRaw(editor.getCurrentContent()));
    this.props.onSave(htmlDetails);
    //this.props.close()
  };

  saveAndClose = () => {
    this.onSave();
    this.props.close();
  };

  render() {
    const {title, close, isOpen, classes, proposal, edit, review} = this.props;
    return (
      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="lg"
        fullWidth={true}
        classes={{paper: classes.dialogPaper}}
      >
        <div className="p-3">
          <div>
            <h2>{title} </h2>
            <h3>Proposal {proposal.number}</h3>
          </div>

          <div className="review-container mb-1">
            {edit && (
              <Editor
                editorState={this.state.editor}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class editor_container"
                toolbar={{
                  list: {inDropdown: true},
                  textAlign: {inDropdown: true},
                  link: {inDropdown: true},
                }}
                onEditorStateChange={this.onEditorChange}
              />
            )}

            {!edit && <div dangerouslySetInnerHTML={this.createMarkup(review)} />}
          </div>

          {edit && (
            <div>
              <div className="d-flex justify-content-end mb-2">
                <Button
                  size="sm"
                  variant="contained"
                  color="primary"
                  onClick={this.onSave}
                  autoFocus
                  style={{color: '#fff'}}
                >
                  Save text
                </Button>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  style={{marginRight: '1rem'}}
                  size="md"
                  variant="outlined"
                  color="primary"
                  onClick={close}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  variant="contained"
                  color="primary"
                  onClick={this.saveAndClose}
                  autoFocus
                >
                  Save and Close
                </Button>
              </div>
            </div>
          )}

          {!edit && (
            <DialogActions>
              <Button size="md" variant="contained" color="primary" onClick={close}>
                Close
              </Button>
            </DialogActions>
          )}
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ManageReview);
