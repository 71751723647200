import React, {Component} from 'react';
import {connect} from 'react-redux';
import {styled} from '@material-ui/styles';
import {sortProposals} from '../redux/actions/proposalsActions';
import Select from '../inputs/Select';

const options = [
  {
    label: 'Number - First to last',
    value: 1,
  },
  {
    label: 'Number - Last to first',
    value: 2,
  },
  {
    label: 'Unseen first',
    value: 3,
  },
  {
    label: 'Seen first',
    value: 4,
  },
  {
    label: 'Category',
    value: 5,
  },
];

const options2 = [
  {
    label: 'Number - First to last',
    value: 1,
  },
  {
    label: 'Number - Last to first',
    value: 2,
  },
];

const SortBy = styled('div')(({theme}) => ({
  color: theme.modeColors.proposalTitle,
}));

class Sort extends Component {
  state = {
    value: 1,
  };

  handleChange = event => {
    this.setState({value: event.target.value});
    this.props.sortProposals(event.target.value);
  };

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;

    return (
      <div className="d-flex align-items-center">
        <SortBy className="sm pr-1">Sort by:</SortBy>
        <div>
          <Select
            onChange={this.handleChange}
            value={this.state.value}
            options={readOnly ? options2 : options}
            fixedWidth
          />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {sortProposals}
)(Sort);
