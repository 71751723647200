import authReducer from './authReducer';
import proposalsReducer from './proposalsReducer';
import dataBaseReducer from './dataBaseReducer';
import {combineReducers} from 'redux';
import {firestoreReducer} from 'redux-firestore';
import {firebaseReducer} from 'react-redux-firebase';
import {reducer as formReducer} from 'redux-form';

const rootReducer = combineReducers({
  auth: authReducer,
  proposals: proposalsReducer,
  dataBase: dataBaseReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  form: formReducer,
});

export default rootReducer;
