import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {filterProposals} from '../../redux/actions/proposalsActions';
import _ from 'lodash';
import NestedMultipleSelect from '../../inputs/NestedMultipleSelect';
import * as searchHelper from '../../util/searchHelper';

class FilterElement extends Component {
  state = {
    values: [],
  };

  handleChange = event => {};

  componentDidMount() {
    const search = searchHelper.getSearchQuery(this.props.history.location.search);
    if (search.advanced_search) {
      this.setState({values: search.advanced_search.split(',')});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSearch = searchHelper.getSearchQuery(nextProps.history.location.search);
    const oldSearch = this.state.values.join(',');

    if (newSearch.advanced_search !== oldSearch) {
      this.setState({
        values: newSearch.advanced_search ? newSearch.advanced_search.split(',') : [],
      });
    }
  }

  selectAll = elements => {
    const newValues = [].concat.apply([], _.map(elements, (val, key) => val));
    this.setState({values: newValues});
  };

  clearAll = () => {
    this.setState({values: []});
  };

  checker = (arr, target) => target.every(v => arr.includes(v));

  clickGroupOptions = (group, elements) => {
    const {values} = this.state;
    const selected = this.checker(values, elements[group]);
    if (!selected) {
      const newValues = _.uniq([...values, ...elements[group]]);
      this.setState({values: newValues});
    } else {
      const newValues = values.filter(function(el) {
        return elements[group].indexOf(el) < 0;
      });
      this.setState({values: newValues});
    }
  };

  clickOption = option => {
    const {values} = this.state;
    if (values.includes(option)) {
      const newValues = values.filter(val => val !== option);
      this.setState({values: newValues});
    } else {
      const newValues = values.concat(option);
      this.setState({values: newValues});
    }
  };

  getElements = proposals => {
    // Get proposals keys
    const proposalKeys = Object.keys(proposals);
    const filtered = {};

    proposalKeys.forEach(
      key =>
        (filtered[key] = Object.values(proposals[key].elements).map(
          element => element.name
        ))
    );

    return filtered;
  };

  applyFilters = () => {
    const {values} = this.state;
    const {filterProposals, history} = this.props;
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'advanced_search',
        values
      ),
    });
    filterProposals(history.location.search);
  };

  render() {
    if (!_.isEmpty(this.props.proposals)) {
      const elements = this.getElements(this.props.proposals);

      return (
        <div id="filter-advanced">
          <NestedMultipleSelect
            options={elements}
            values={this.state.values}
            onChange={this.handleChange}
            selectAll={() => this.selectAll(elements)}
            clearAll={this.clearAll}
            placeholder="Filter by element"
            clickGroupOptions={group => this.clickGroupOptions(group, elements)}
            clickOption={this.clickOption}
            apply={this.applyFilters}
          />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    proposals: state.proposals.data,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {filterProposals}
  )(FilterElement)
);
