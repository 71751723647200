import axios from 'axios';
import * as searchHelper from '../../util/searchHelper';
import _ from 'lodash';
import {filtersData, advancedData} from '../../util/Helper';

export const getPropsals = () => {
  return dispatch => {
    dispatch({
      type: 'FETCH_PROPOSALS_LOADING',
    });

    axios
      .get(process.env.REACT_APP_API)
      .then(data => {
        // console.log('data', data);
        dispatch({
          type: 'FETCH_PROPOSALS_SUCCESS',
          payload: data.data,
        });
      })
      .catch(err => {
        // console.log('err', err);
        dispatch({
          type: 'FETCH_PROPOSALS_ERROR',
          payload: err,
        });
      });
  };
};

export const filterProposals = link => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const state = getState(),
      profile = state.firebase.profile,
      propsalsData = state.firestore.data.proposals,
      initData = state.proposals.initData;
    const searchQuery = searchHelper.getSearchQuery(link);
    const publicClass = searchQuery.classes;
    const privateTags = searchQuery.private_tags;
    const publicTags = searchQuery.public_tags;
    const hasNotes = searchQuery.has_notes;
    const show = searchQuery.show;
    const search = searchQuery.search;
    const advancedSearch = searchQuery.advanced_search;
    if (link) {
      if (search) {
        const newData = searchHelper.searchData(search, initData);
        dispatch({
          type: 'SEARCH_PROPOSALS_SUCCESS',
          payload: newData,
        });
      } else {
        if (advancedSearch) {
          let data = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            initData,
            profile,
            propsalsData
          );

          data =
            _.isEmpty(data) &&
            (!publicClass && !privateTags && !publicTags && !hasNotes && !show)
              ? initData
              : data;
          const newData = advancedData(advancedSearch, data);
          dispatch({
            type: 'ADVANCED_SEARCH_SUCCESS',
            payload: newData,
          });
        } else {
          const newData = filtersData(
            show,
            publicClass,
            privateTags,
            publicTags,
            hasNotes,
            initData,
            profile,
            propsalsData
          );
          dispatch({
            type: 'FILTER_PROPOSALS_SUCCESS',
            payload: newData,
          });
        }
      }
    } else {
      dispatch({
        type: 'FILTER_PROPOSALS_SUCCESS',
        payload: initData,
      });
    }
  };
};

export const sortProposals = val => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const state = getState();
    const {proposals} = state;
    const {profile} = state.firebase;
    const proposalsData = state.firestore.data.proposals;
    const classes = state.firestore.ordered.PublicClasses;

    let newData, dataToSort;

    if (proposals.search) {
      dataToSort = proposals.searchProposals;
    } else {
      dataToSort = proposals.data;
    }

    // sort number first to last
    if (val === 1) {
      newData = _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => val[1].number));
    }

    // sort number last to first
    if (val === 2) {
      newData = _.fromPairs(_.orderBy(_.toPairs(dataToSort), val => -val[1].number));
    }

    // sort UnSeen First
    if (val === 3) {
      if (profile.proposals) {
        const proposals_seen = _.map(
          _.pickBy(profile.proposals, val => val.lastSeen),
          (val, key) => key
        );
        newData = _.fromPairs(
          _.orderBy(_.toPairs(dataToSort), val => proposals_seen.includes(val[0]))
        );
      } else {
        newData = dataToSort;
      }
    }

    // sort Seen First
    if (val === 4) {
      if (profile.proposals) {
        const proposals_seen = _.map(
          _.pickBy(profile.proposals, val => val.lastSeen),
          (val, key) => key
        );
        newData = _.fromPairs(
          _.orderBy(_.toPairs(dataToSort), val => !proposals_seen.includes(val[0]))
        );
      } else {
        newData = dataToSort;
      }
    }

    // sort by Category
    if (val === 5) {
      if (proposalsData) {
        newData = {};
        let results = [];

        // sort the proposals with class
        const classesNames = _.map(classes, val => val.class);
        const proposalsClass = _.pickBy(proposalsData, val => val.class);

        const sortedDataWithSameClassNameByNumber = (comparedClassName, data) => {
          let sortedData = [];

          _.forEach(proposalsClass, (value, key) => {
            if (value.class.class === comparedClassName && data[key]) {
              sortedData.push([key, data[key]]);
            }
          });

          return sortedData.sort((a, b) => (a[1].number > b[1].number ? 1 : -1));
        };

        _.forEach(classesNames, (value, index) => {
          const sortedResults = sortedDataWithSameClassNameByNumber(
            classesNames[index],
            dataToSort
          );

          results = [...results, ...sortedResults];
        });

        _.forEach(results, value => (newData[value[0]] = value[1]));

        //add the proposals without classes
        const proposalsClassKey = _.map(newData, (val, key) => key);

        _.forEach(dataToSort, (val, key) => {
          if (!proposalsClassKey.includes(key) && val) {
            newData[key] = val;
          }
        });
      } else {
        newData = dataToSort;
      }
    }

    if (proposals.search) {
      dispatch({
        type: 'SORT_SEARCH_PROPOSALS_SUCCESS',
        payload: newData,
      });
    } else {
      dispatch({
        type: 'SORT_ALL_PROPOSALS_SUCCESS',
        payload: newData,
      });
    }
  };
};

export const updateCookies = data => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_PROPOSALS_COOKIES',
      payload: data,
    });
  };
};
