import React, {Component} from 'react';
import _ from 'lodash';
import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';
import {addToDataBase, deleteFromDataBase} from '../redux/actions/dataBase';
import {filterProposals} from '../redux/actions/proposalsActions';
import {withStyles} from '@material-ui/core/styles';
import Zoom from '../single-proposal/Zoom';
import Element from '../single-proposal/Element';
import Elements from '../single-proposal/Elements';
import * as Helper from '../util/Helper.js';
import Sidebar from '../single-proposal/Sidebar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import queryString from 'query-string';
import Spinner from '../spinner/Spinner';

const styles = theme => ({
  elements: {
    flex: 1,
    padding: 16,
  },
  tabsRoot: {
    overflow: 'visible',
    position: 'sticky',
    top: 40,
    '& .MuiTabs-scroller': {
      overflow: 'visible !important',
    },
  },
});

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 88,
    fontWeight: 700,
    fontSize: 26,
    fontFamily: 'HarmoniaSansStd !important',
    color: '#353c43',
    minHeight: 50,
    padding: 0,
    zIndex: 1002,
    overflow: 'visible',
    '&:hover': {
      color: '#353c43',
      opacity: 1,
    },
    '&$selected': {
      color: '#353c43',
      backgroundColor: '#fff',
      boxShadow: ' 0 -2px 3px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

class SingleProposal extends Component {
  state = {
    tab: 0,
    zoom: 3,
    chosenProposals: [],
    chosenElements: {},
    isModalOpen: false,
    singleElement: null,
    proposalLink: '',
    proposalName: '',
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const elements = query.elements;
    if (elements) {
      const elementsQuery = elements.split(',');
      this.getComparingElements(elementsQuery);
    } else {
      this.props.history.push('/');
    }
  }

  getComparingElements = elements => {
    const {
      proposals: {data},
    } = this.props;
    const chosenProposals = [];
    const chosenElements = {};
    _.forEach(elements, function(element) {
      _.forEach(data, function(value, key) {
        const elementsKey = Object.keys(value.elements);
        if (elementsKey.includes(element)) {
          chosenProposals.push(key);
          chosenElements[element] = value.elements[element];
        }
      });
    });
    if (chosenProposals.length !== elements.length || elements.length > 3) {
      this.props.history.push('/');
    } else {
      this.setState({chosenProposals, chosenElements});
    }
  };

  handleChange = (e, v) => {
    console.log(v);
    this.setState({tab: v});
  };

  zoomIn = () => {
    const {zoom} = this.state;
    if (zoom < 6) {
      this.setState({zoom: zoom + 1});
    }
  };

  zoomOut = () => {
    const {zoom} = this.state;
    if (zoom > 1) {
      this.setState({zoom: zoom - 1});
    }
  };

  openSingleElement = (val, key, proposal) => {
    this.setState({
      isModalOpen: true,
      singleElement: val,
      id: key,
      proposalLink: proposal[0],
      proposalName: proposal[1].number,
    });
  };

  closeModal = () => this.setState({isModalOpen: false});

  render() {
    const {classes, proposals, PublicClasses, profile, proposalsData} = this.props;
    const {tab, chosenProposals, chosenElements} = this.state;
    if (proposals.loading) {
      return <Spinner />;
    }
    const proposalId = chosenProposals[tab];
    const proposalClass = Helper.getProposalClass(
      proposalId,
      proposalsData,
      PublicClasses
    );

    const proposal = proposals.data[proposalId];
    return (
      <>
        {proposal && (
          <div className="d-flex">
            {/*all Elements*/}
            <div className={classes.elements}>
              <Zoom
                zoomIn={this.zoomIn}
                zoomOut={this.zoomOut}
                zoomLevel={this.state.zoom}
              />
              <div className="p-2">
                <Elements
                  openSingleElement={this.openSingleElement}
                  zoom={this.state.zoom}
                  elements={chosenElements}
                />
              </div>
            </div>
            <div>
              <Tabs
                value={tab}
                textColor="primary"
                onChange={this.handleChange}
                aria-label=""
                variant="fullWidth"
                classes={{
                  root: classes.tabsRoot,
                  scroll: classes.tabsScroll,
                }}
                TabIndicatorProps={{style: {display: 'none'}}}
              >
                {chosenProposals.map((proposal, i) => (
                  <AntTab key={i} label={proposals.data[proposal].number} />
                ))}
              </Tabs>
              <Sidebar
                expand={true}
                proposal={proposal}
                profile={profile}
                readOnly={false}
                proposalClass={proposalClass}
                proposalId={proposalId}
                toggleSidebar={this.toggleSidebar}
                OpenEditCalssesModal={this.OpenEditCalssesModal}
                withoutHead={true}
              />
            </div>
            <Element
              id={this.state.id}
              element={this.state.singleElement}
              elements={chosenElements}
              close={this.closeModal}
              isOpen={this.state.isModalOpen}
              link={this.state.proposalLink}
              proposalName={this.state.proposalName}
              comparing
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    proposals: state.proposals,
    profile: state.firebase.profile,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsData: state.firestore.ordered.proposals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
    filterProposals: search => dispatch(filterProposals(search)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{collection: 'PublicClasses'}, {collection: 'proposals'}])
)(withSnackbar(withStyles(styles)(SingleProposal)));
