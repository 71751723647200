import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles({
  root: {
    color: props => props.color,
    borderRadius: '0%',
    padding: '0px',
    margin: '0 2px',
    width: '14px',
    height: '14px',
    overflow: 'hidden',
    background: props => props.color,
    bordrColor: props => props.color,
    border: `2px solid`,
    '&:hover': {
      background: props => props.color,
    },
  },
  checked: {
    border: `2px solid black`,
  },
});

export default function({color, ...props}) {
  const classes = useStyles({
    color: color,
  });
  return (
    <Radio
      classes={{root: classes.root, checked: classes.checked}}
      {...props}
      color="default"
    />
  );
}
