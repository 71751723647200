import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Settings from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import Tooltip from '@material-ui/core/Tooltip';
import AdapterLink from '../util/AdapterLink';
import ProfileMenu from './ProfileMenu';
import {signOut} from '../redux/actions/authActions';
import {updateCookies} from '../redux/actions/proposalsActions';
import {withRouter, Link} from 'react-router-dom';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import MySnackbarContentWrapper from '../inputs/Snackbar';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from '../modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import queryString from 'query-string';
import cookie from 'react-cookies';
import TampereLogo from '../assets/Tampere_logo.png';
import * as Helper from '../util/Helper.js';

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
  },

  toolbar: {
    position: 'relative',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    padding: '0 32px',
    background: theme.modeColors.navBackground,
    zIndex: 10,
  },
  title: {
    display: 'block',
    marginBottom: -3,
    marginLeft: '8px',
  },
  sectionDesktop: {
    display: 'flex',
  },

  icons: {
    padding: '6px',
  },

  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px',
  },
  logo: {
    width: '15rem',
    maxWidth: '100%',
    height: 'auto',
    marginLeft: '-1.5rem',
  },
});

class Navbar extends React.Component {
  state = {
    eventKeys: '', // for keydown event
    lastKeyTime: Date.now(), // for keydown event
    snackbarPopupType: null, // for display Snackbar,
    selectPage: false,
    page: 'page 1',
    viewMode: 'normal',
    selectMode: false,
    isSecondPhase: false,
  };

  intervalID = 0;

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeyDown);
    this.intervalID = setInterval(this.changeCookies, 250);

    // Check current URL to display only six proposals particcipate in second phase
    if (window.location.href.includes('second-phase-participants')) {
      this.setState({isSecondPhase: true});
    } else {
      this.setState({isSecondPhase: false});
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {cookies} = this.props.proposals;
    const {cookies: prevCookies} = prevProps.proposals;
    const cookiesQuery = queryString.parse(cookies.replace(/; /g, '&'));
    if (
      cookies !== prevCookies ||
      (cookiesQuery.JUG_MODE && cookiesQuery.JUG_MODE === 'full')
    ) {
      this.updateViewMode(cookiesQuery);
    }
  }

  UNSAFE_componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyDown);
    clearInterval(this.intervalID);
  };

  changeCookies = () => {
    const savedCookies = document.cookie;
    if (savedCookies !== this.props.proposals.cookies && !this.props.profile.isEmpty) {
      this.props.updateCookies(savedCookies);
    }
  };

  updateViewMode = cookiesQuery => {
    const {pathname} = this.props.location;
    if (cookiesQuery.JUG_MODE) {
      if (cookiesQuery.JUG_MODE === 'full' && !pathname.includes('proposal/')) {
        const id = Object.keys(this.props.proposals.data)[0];
        this.props.history.push(`/proposal/${id}`);
      }
    }
  };

  navigation = () => {
    const {proposals, location, PublicClasses, proposalsOrdered} = this.props;
    const {isSecondPhase} = this.state;
    let secondPhaseProposalsData = {};

    if (proposals.data) {
      // Filter proposals data to display only 6 proposals participate in second phase on https//second-phase-participants.viinikanlahti.weup.city page
      _.filter(proposals.data, (value, key) => {
        const proposalClass = Helper.getProposalClass(
          key,
          proposalsOrdered,
          PublicClasses
        );

        if (
          isSecondPhase &&
          proposalClass &&
          proposalClass.class.includes('Second phase participants')
        ) {
          return (secondPhaseProposalsData = {...secondPhaseProposalsData, [key]: value});
        }
      });

      const proposalsObj = proposals.search
        ? proposals.searchProposals
        : isSecondPhase
        ? secondPhaseProposalsData
        : proposals.data;
      const sortedProposalKeys = _.map(proposalsObj, (value, key) => key);
      const arrayLength = sortedProposalKeys.length;
      const proposalId = location.pathname.replace('/proposal/', '');
      const currentIndex = sortedProposalKeys.indexOf(proposalId);

      return (
        <div className="d-flex align-items-center">
          {/* Go back */}
          {currentIndex !== 0 && (
            <IconButton
              onClick={() => this.navigate(proposalsObj, currentIndex, 'back')}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
              className="mr-1"
              size="small"
              color="inherit"
            >
              <ArrowBack fontSize="small" />
            </IconButton>
          )}

          {/* Current proposal number/ total proposals */}
          <div>
            {currentIndex + 1}/{arrayLength} results
          </div>

          {/* Go forward */}
          {currentIndex !== arrayLength - 1 && (
            <IconButton
              onClick={() => this.navigate(proposalsObj, currentIndex, 'next')}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
              className="ml-1"
              size="small"
              color="inherit"
            >
              <ArrowForward fontSize="small" />
            </IconButton>
          )}
        </div>
      );
    }
  };

  // Go back or next
  navigate = (proposalsObj, currentIndex, navCase = 'next') => {
    const {history, location} = this.props;
    const query = queryString.parse(location.search);

    const sortedProposalKeys = _.map(proposalsObj, (value, key) => key);

    const targetIndex = navCase === 'next' ? currentIndex + 1 : currentIndex - 1;
    let goToKey = sortedProposalKeys[targetIndex];

    if (goToKey) {
      // Reset cookie values when user goes to new proposal
      cookie.save('j_proposal', goToKey, {path: '/'});
      cookie.save('j_count', 0, {path: '/'});

      const obj = {pathname: `/proposal/${goToKey}`};

      if (query.view_mode) {
        obj.search = `?view_mode=${query.view_mode}`;
      }

      if (query.start_index) {
        obj.search += `&start_index=${query.start_index}`;
      }

      history.push(obj);
    }
  };

  handleKeyDown = event => {
    const {proposals, history, location} = this.props;
    const isNumber = isFinite(event.key);

    if (isNumber && event.target.nodeName !== 'TEXTAREA') {
      const eventTime = Date.now();
      // Reset eventKeys after 1s between keypress
      if (eventTime - this.state.lastKeyTime > 1000) {
        this.setState({eventKeys: ''});
      }
      // Set new event keys, new lastKeyTime and snackbarPopupType states
      this.setState({
        eventKeys: this.state.eventKeys + event.key,
        lastKeyTime: eventTime,
        snackbarPopupType: 'info',
      });
    }

    if (event.key === 'Enter') {
      // Convert key from string to number
      const requestedKey = parseInt(this.state.eventKeys);
      const proposalCount = proposals.data ? Object.keys(proposals.data).length : 0;
      const query = queryString.parse(location.search);

      if (requestedKey <= proposalCount) {
        Object.entries(proposals.data).forEach(([key, proposal]) => {
          if (requestedKey === proposal.number) {
            // Go to new proposal
            // Reset cookie values when user goes to new proposal
            cookie.save('j_proposal', key, {path: '/'});
            cookie.save('j_count', 0, {path: '/'});

            const obj = {pathname: `/proposal/${key}`};
            if (query.view_mode) {
              obj.search = 'view_mode=true';
            }
            if (query.start_index) {
              obj.search += `&start_index=${query.start_index}`;
            }
            history.push(obj);
          }
        });
      } else {
        this.setState({snackbarPopupType: 'error'});
      }
    }

    if (event.code === 'KeyP' && event.target.nodeName === 'BODY') {
      this.setState({selectPage: true});
    }

    if (event.code === 'KeyM' && event.target.nodeName === 'BODY') {
      this.setState({selectMode: true});
    }

    const count = cookie.load('j_count') ? parseInt(cookie.load('j_count')) : 0;

    if (event.code === 'KeyN' && event.target.nodeName === 'BODY') {
      cookie.save('j_count', count + 3, {path: '/'});
    }

    if (event.code === 'KeyB' && event.target.nodeName === 'BODY') {
      cookie.save('j_count', count > 3 ? count - 3 : 0, {path: '/'});
    }
  };

  handleCloseSnackBar = () => {
    this.setState({
      eventKeys: '',
      snackbarPopupType: null,
    });
  };

  renderSnackbarPopup = (type, number) => {
    const {eventKeys, snackbarPopupType} = this.state;
    let snackbarMessage = '';

    type === 'error'
      ? (snackbarMessage = `Proposal number ${number} out of range`)
      : (snackbarMessage = `Press 'Enter' to go to proposal number ${number}`);

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!eventKeys && !!snackbarPopupType}
        autoHideDuration={3000}
        onClose={this.handleCloseSnackBar}
      >
        <MySnackbarContentWrapper
          onClose={this.handleCloseSnackBar}
          variant={type}
          message={snackbarMessage}
        />
      </Snackbar>
    );
  };

  closeSelectPage = () => this.setState({selectPage: false});
  closeSelectMode = () => this.setState({selectMode: false});

  handleChangeRadio = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  onSaveSelectPage = () => {
    window.self.name = this.state.page;
    this.closeSelectPage();
  };

  onSaveSelectMode = () => {
    cookie.remove('JUG_MODE', {path: '/'});
    cookie.save('JUG_MODE', this.state.viewMode, {path: '/'});
    if (this.state.viewMode === 'full') {
      const id = Object.keys(this.props.proposals.data)[0];
      cookie.save('j_proposal', id, {path: '/'});
      cookie.save('j_count', 0, {path: '/'});
    }
    this.closeSelectMode();
  };

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY;
    const {classes, profile, location} = this.props;
    const inProposal = location.pathname.includes('proposal');
    const {
      eventKeys,
      snackbarPopupType,
      selectPage,
      page,
      selectMode,
      viewMode,
    } = this.state;

    if (profile && !profile.isEmpty) {
      return (
        <AppBar className={classes.root} position="sticky" color="default">
          {/* Show info or error snackbar for keydown event */}
          {eventKeys &&
            snackbarPopupType &&
            this.renderSnackbarPopup(snackbarPopupType, eventKeys)}

          {/* Toolbar */}
          <Toolbar disableGutters={true} className={classes.toolbar}>
            {/* Home icons and Profile's name */}
            <div className="d-flex align-items-center">
              <Tooltip classes={{tooltip: classes.tooltip}} title="Home">
                <IconButton
                  component={AdapterLink}
                  to="/"
                  className={classes.icons}
                  color="inherit"
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
              <h5 className={classes.title}>
                {profile.firstName} {profile.lastName}
              </h5>
            </div>

            {/* Project's name */}
            <h3 className="mb-0">
              {inProposal
                ? this.navigation()
                : 'Viinikanlahti International Urban Ideas Competition'}
            </h3>

            {/* Competition's info - Settings - Profile */}
            <div className={classes.sectionDesktop}>
              <Tooltip
                component={AdapterLink}
                to="/competition-info"
                classes={{tooltip: classes.tooltip}}
                title="Competition information"
              >
                <IconButton
                  id="competition-info"
                  className={classes.icons}
                  color="inherit"
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
              {profile.role !== 'judge' && (
                <Tooltip classes={{tooltip: classes.tooltip}} title="Settings">
                  <IconButton
                    component={AdapterLink}
                    to="/settings"
                    className={classes.icons}
                    color="inherit"
                  >
                    <Settings />
                  </IconButton>
                </Tooltip>
              )}
              <ProfileMenu Email={profile.email} />
            </div>

            {/* Select Page Modal */}
            <Modal
              close={this.closeSelectPage}
              isOpen={selectPage}
              onSave={this.onSaveSelectPage}
              title="Select Page"
            >
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="page"
                  name="page"
                  value={page}
                  onChange={this.handleChangeRadio}
                >
                  <FormControlLabel value="page 1" control={<Radio />} label="Page 1" />
                  <FormControlLabel value="page 2" control={<Radio />} label="Page 2" />
                  <FormControlLabel value="page 3" control={<Radio />} label="Page 3" />
                </RadioGroup>
              </FormControl>
            </Modal>

            {/* Select View Modal */}
            <Modal
              close={this.closeSelectMode}
              isOpen={selectMode}
              onSave={this.onSaveSelectMode}
              title="Select View Mode"
            >
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="page"
                  name="viewMode"
                  value={viewMode}
                  onChange={this.handleChangeRadio}
                >
                  <FormControlLabel
                    value="normal"
                    control={<Radio />}
                    label="Normal mode"
                  />
                  <FormControlLabel
                    value="full"
                    control={<Radio />}
                    label="Full Screen Element"
                  />
                  <FormControlLabel
                    value="nested"
                    control={<Radio />}
                    label="Three level mode"
                    disabled
                  />
                  <FormControlLabel
                    value="clicking"
                    control={<Radio />}
                    label="Clicking Mode"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Modal>
          </Toolbar>
        </AppBar>
      );
    } else if (readOnly) {
      return (
        <AppBar className={classes.root} position="sticky" color="default">
          {/* Show info or error snackbar for keydown event */}
          {eventKeys &&
            snackbarPopupType &&
            this.renderSnackbarPopup(snackbarPopupType, eventKeys)}
          <Toolbar disableGutters={true} className={classes.toolbar}>
            <div className="d-flex align-items-center">
              <Link to="/">
                <img src={TampereLogo} alt="Tampere Finland" className={classes.logo} />
              </Link>

              {/* <Tooltip classes={{tooltip: classes.tooltip}} title="Home">
                <IconButton
                  component={AdapterLink}
                  to="/"
                  className={classes.icons}
                  color="inherit"
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip> */}
            </div>
            <h3 className="mb-0">
              {inProposal
                ? this.navigation()
                : 'First Phase Entries of Viinikanlahti International Urban Ideas Competition'}
            </h3>
            <div className={classes.sectionDesktop}>
              {/* <Tooltip
                component={AdapterLink}
                to="/competition-info"
                classes={{tooltip: classes.tooltip}}
                title="Competition information"
              >
                <IconButton
                  id="competition-info"
                  className={classes.icons}
                  color="inherit"
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip> */}
            </div>
          </Toolbar>
        </AppBar>
      );
    }
    return null;
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    proposals: state.proposals,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsOrdered: state.firestore.ordered.proposals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    updateCookies: data => dispatch(updateCookies(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Navbar))
);
