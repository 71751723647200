const initState = {
  cookies: '',
};

const propsalsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_PROPOSALS_LOADING':
      return {...state, loading: true, search: false, advanced: false};
    case 'FETCH_PROPOSALS_SUCCESS':
      return {
        ...state,
        err: false,
        initData: action.payload.competition.proposals,
        data: action.payload.competition.proposals,
        loading: false,
        search: false,
        advanced: false,
      };
    case 'FETCH_PROPOSALS_ERROR':
      return {
        ...state,
        err: action.payload,
        data: false,
        loading: false,
        search: false,
        advanced: false,
      };
    case 'FILTER_PROPOSALS_SUCCESS':
      return {
        ...state,
        err: false,
        data: action.payload,
        loading: false,
        search: false,
        advanced: false,
      };
    case 'SEARCH_PROPOSALS_SUCCESS':
      return {
        ...state,
        search: true,
        advanced: false,
        searchProposals: action.payload.proposals,
        searchElements: action.payload.elements,
        loading: false,
      };
    case 'ADVANCED_SEARCH_SUCCESS':
      return {
        ...state,
        advanced: true,
        search: false,
        advancedElements: action.payload,
        loading: false,
      };
    case 'SORT_ALL_PROPOSALS_SUCCESS':
      return {
        ...state,
        err: false,
        data: action.payload,
        loading: false,
        search: false,
        advanced: false,
      };
    case 'SORT_SEARCH_PROPOSALS_SUCCESS':
      return {...state, searchProposals: action.payload};
    case 'UPDATE_PROPOSALS_COOKIES': {
      return {...state, cookies: action.payload};
    }
    default:
      return state;
  }
};

export default propsalsReducer;
