import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import * as searchHelper from '../../util/searchHelper';
import {filterProposals} from '../../redux/actions/proposalsActions';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    border: 'solid 1px #ccced0',
    position: 'relative',
    backgroundColor: theme.modeColors.inputBackground,
  },
  input: {
    marginLeft: 12,
    flex: 1,
    fontSize: '0.875rem',
    padding: '3px 4px 0px',
    backgroundColor: theme.modeColors.inputBackground,
  },
  iconButton: {
    padding: 8,
  },
  searchIcon: {
    fontSize: '1rem',
  },
  searchTooltip: {
    position: 'absolute',
    fontSize: '0.75rem',
    width: '100%',
    top: '2rem',
    background: theme.modeColors.inputBackground,
    border: '1px solid #ccced0',
    padding: '0.5rem',
    boxSizing: 'border-box',
  },
});

class SearchBar extends Component {
  state = {
    value: '',
    searchTooltip: false,
  };

  componentDidMount() {
    const search = searchHelper.getSearchQuery(this.props.history.location.search);
    if (search.search) {
      this.setState({value: search.search});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSearch = searchHelper.getSearchQuery(nextProps.history.location.search);
    const oldSearch = this.state.value;

    if (oldSearch.search !== oldSearch) {
      this.setState({value: newSearch.search ? newSearch.search : ''});
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const {value} = this.state;
    const {history, filterProposals} = this.props;
    history.push({
      search: searchHelper.updateSearchQuery(history.location.search, 'search', value),
    });
    filterProposals(history.location.search);
  };

  handleChange = event => {
    this.setState({value: event.target.value});
  };

  render() {
    const {classes} = this.props;
    const {value, searchTooltip} = this.state;
    return (
      <form id="search-proposals" onSubmit={this.handleSubmit}>
        <Paper className={classes.root} elevation={1}>
          <InputBase
            value={value}
            onChange={this.handleChange}
            className={classes.input}
            placeholder="Search ..."
            onFocus={() => this.setState({searchTooltip: true})}
            onBlur={() => this.setState({searchTooltip: false})}
          />
          <IconButton type="submit" className={classes.iconButton} aria-label="Search">
            <SearchIcon className={classes.searchIcon} />
          </IconButton>
          {searchTooltip && (
            <div className={classes.searchTooltip}>
              Search by <b>words</b> or proposal <b>numbers</b> by entering individual
              numbers like 2, 15, 45 or range like 34-56.
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    null,
    {filterProposals}
  )(withStyles(styles)(SearchBar))
);
