import React, {Component} from 'react';
import cookie from 'react-cookies';
import Tour from 'reactour';
import Button from '../inputs/Button';

const steps = [
  {
    id: '',
    header: 'Welcome',
    body: 'Here are some tips on how to use this judging tool.',
  },
  {
    id: '#competition-info',
    header: 'Competition information',
    body: 'You can read all about the competition here.',
  },
  {
    id: '#my-profile',
    header: 'My profile ',
    body: 'You can change your password and log out here.',
  },
  {
    id: '#search-proposals',
    header: 'Search proposals, elements or text ',
    body:
      'You can search proposals by words or proposal numbers by entering individual numbers like 2, 15, 45 or range like 34-56.',
  },
  {
    id: '#filter-class',
    header: 'Filter proposals by class ',
    body:
      'Once the secretary will arrange the proposals to classes (upper, middle, lower class), you will able to filter the proposals by class here. ',
  },
  {
    id: '#filter-public-tags',
    header: 'Filter proposals by public tags ',
    body:
      'Once the secretary will add public tags to proposals (great floorplans, good volumes etc.), you will able to filter the proposals by public tags here. ',
  },
  {
    id: '#filter-private-tags',
    header: 'Filter proposals by private tags ',
    body:
      'You can add your private tags to proposals on proposal page. These are for your eyes only. Once you add tags to proposals, you can filter the proposals by private tags here.',
  },
  {
    id: '#filter-has-notes',
    header: 'Filter proposals by notes',
    body:
      'Secretary can add public notes to proposals and you can add your private notes to proposals. Once notes are added, you can filter your proposals by notes here.',
  },
  {
    id: '#filter-advanced',
    header: 'Advanced search by elements',
    body: 'You can search proposal elements here.',
  },
  {
    id: '',
    header: 'You are all set',
    body: 'You are all set.',
  },
].map((step, i) => {
  return {
    selector: step.id,
    content: ({goTo, close}) => (
      <div className="onboarding">
        <h2>{step.header}</h2>
        <div className="sm border-bottom pb-2 mb-2">{step.body}</div>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              if (i > 0 && !step.id) {
                close();
              } else {
                goTo(i + 1);
              }
            }}
            size="md"
            variant="contained"
            color="primary"
          >
            {i === 0 ? 'Start' : 'Got it'}
          </Button>
        </div>
      </div>
    ),
    position: 'center',
    stepInteraction: false,
    style: {
      minWidth: 432,
      padding: 32,
    },
  };
});

class Onboarding extends Component {
  state = {
    isTourOpen: false,
  };

  componentDidMount() {
    const firstTimeRunning = cookie.load('firstTime');

    if (!firstTimeRunning) {
      cookie.save('firstTime', 'firstTime');
      this.setState({isTourOpen: true});
    }
  }

  closeTour = () => this.setState({isTourOpen: false});

  render() {
    return (
      <Tour
        closeWithMask={false}
        steps={steps}
        showNumber={false}
        showNavigation={false}
        showCloseButton
        showButtons={false}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.closeTour}
      />
    );
  }
}

export default Onboarding;
