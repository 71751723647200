import React, {Component} from 'react';
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';
import {Document, View, Text, StyleSheet, Image, Font} from '@react-pdf/renderer';
import RalewayNormal from '../assets/fonts/regular.ttf';
import RalewayBold from '../assets/fonts/bold.ttf';
import TampereIcon from '../assets/icons/tampere.png';
import * as Helper from '../util/Helper.js';
import {rating_fields} from '../single-proposal/rating_fields';
import {LineChart, Line, YAxis} from 'recharts';
import PdfPages from './PdfPages';

Font.register({
  family: 'Raleway',
  fonts: [
    {src: RalewayNormal}, // font-style: normal, font-weight: normal
    {src: RalewayBold, fontStyle: 'normal', fontWeight: 700},
  ],
});

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  head_small: {
    fontSize: 8,
    marginBottom: 4,
    color: '#353c43',
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  head_medium: {
    fontSize: 12,
    marginBottom: 6,
    color: '#353c43',
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  head_big: {
    fontSize: 28,
    color: '#353c43',
    fontWeight: 700,
  },
});

class ExportPdf extends Component {
  getImages = data => {
    const elements = _.filter(data.elements, (value, key) => value.type === 'panel');
    const bigImageUrl =
      elements.length > 5
        ? elements[5].pdf_thumbnail_url
        : elements[elements.length - 1].pdf_thumbnail_url;

    return (
      <View
        style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
      >
        <View style={{width: '15%'}}>
          {elements.map(({pdf_thumbnail_url}, i) => {
            const marginTopValue = i === 0 ? 10 : 5;
            if (i < 5) {
              return (
                <Image
                  key={i}
                  style={{marginTop: marginTopValue, maxWidth: '100%', height: 86}}
                  src={process.env.REACT_APP_URL + pdf_thumbnail_url}
                />
              );
            }
            return null;
          })}
        </View>

        <View style={{width: '75%'}}>
          <Image
            style={{marginTop: 10, width: '100%', height: 430}}
            src={process.env.REACT_APP_URL + bigImageUrl}
          />
        </View>
      </View>
    );
  };

  getpageHeader = data => {
    return (
      <>
        <View style={styles.header}>
          <View>
            <Text style={{...styles.head_small, color: '#9f9f9f'}}>
              First phase entries
            </Text>
            <Text style={{...styles.head_medium, color: '#9f9f9f', marginBottom: 0}}>
              Viinikinlahti International Urban Ideas Competition 2019-2020
            </Text>
          </View>
          <View>
            <Image style={{marginTop: 0, width: 140, maxHeight: 50}} src={TampereIcon} />
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            flexDirection: 'row',
            marginTop: -6,
            marginBottom: 6,
          }}
        >
          <Text style={styles.head_big}>{data.number}</Text>
          <Text
            style={{
              ...styles.head_medium,
              paddingLeft: 16,
              marginBottom: 4,
              textTransform: 'capitalize',
            }}
          >
            {data.name}
          </Text>
        </View>
      </>
    );
  };

  gitStatistic = proposal => {
    if (proposal && proposal.excelData) {
      const {statistic} = proposal.excelData;
      return statistic
        ? _.map(statistic, (value, key) => {
            const statisticKey = Object.keys(value)[0];
            const statisticValue = value[Object.keys(value)[0]];
            const statisticValueArr = statisticValue.split(' ');
            if (statisticValueArr[0].indexOf(',') >= 0) {
              statisticValueArr[0] = statisticValueArr[0].replace(',', '.');
            }
            if (/^[0-9]+(\.)?[0-9]*$/.test(statisticValueArr[0])) {
              const floatVal = parseFloat(statisticValueArr[0]);
              if (floatVal && !Number.isInteger(floatVal)) {
                statisticValueArr[0] = Number(statisticValueArr[0]).toFixed(2);
              }
            }
            // Add space between thousands
            statisticValueArr[0] = statisticValueArr[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            return statisticValueArr ? (
              <View
                key={key}
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}
              >
                <Text style={{fontSize: 8, fontFamily: 'Raleway', width: 134}}>
                  {statisticKey}:
                </Text>
                <Text style={{fontSize: 8, fontFamily: 'Raleway'}}>
                  {statisticValueArr.join(' ')}
                </Text>
              </View>
            ) : null;
          })
        : null;
    }
    return null;
  };

  htmToArray = html => {
    if (html) {
      const newHtml = html
        .replace(/<\s*[a-z][^>]*>/g, '')
        .replace(/\n/g, '')
        .replace(/<\/span>/g, '')
        .replace(/<\/em>/g, '')
        .replace(/<\/strong>/g, '')
        .replace(/<\/a>/g, '')
        .replace(/<\/ins>/g, '')
        .replace(/<\/del>/g, '')
        .replace(/<\/sup>/g, '')
        .replace(/<\/sub>/g, '')
        .replace(/<\/code>/g, '')
        .replace(/<\/blockquote>/g, '')
        .replace(/<\/p>/g, 'arraypoint')
        .replace(/<\/h1>/g, 'arraypoint')
        .replace(/<\/h2>/g, 'arraypoint')
        .replace(/<\/h3>/g, 'arraypoint')
        .replace(/<\/h4>/g, 'arraypoint')
        .replace(/<\/h5>/g, 'arraypoint')
        .replace(/<\/h6>/g, 'arraypoint');
      return newHtml.split('arraypoint');
    }
    return [];
  };

  getLineChartValues = rate => {
    const linesChart = rating_fields.filter(item => item.name !== 'overall');
    const values = linesChart.map(item => {
      const currentRate = rate[item.name] ? rate[item.name] : 0;
      return {...item, rate: currentRate};
    });

    const rateDiv = (
      <div className="position-absolute">
        <LineChart width={150} height={100} data={values}>
          <Line
            dot={false}
            type="monotone"
            dataKey="rate"
            stroke="#3f51b5"
            strokeWidth="4"
          />
          <YAxis type="number" domain={[0, 5]} hide={true} />
        </LineChart>
      </div>
    );
    const rateHtml = ReactDOMServer.renderToStaticMarkup(rateDiv);
    return rateHtml.substring(rateHtml.lastIndexOf('d=') + 3, rateHtml.lastIndexOf('">'));
  };

  getPdf = data => {
    return _.map(data, (proposalData, id) => {
      const {proposalsOrdered, PublicClasses} = this.props;
      const images = this.getImages(proposalData);
      const statistic = this.gitStatistic(proposalData);
      const pageHeader = this.getpageHeader(proposalData);
      const publicReview = Helper.getProposalPublicReview(proposalsOrdered, id);
      const publicReviewArray = this.htmToArray(publicReview);
      const proposalClass = Helper.getProposalClass(id, proposalsOrdered, PublicClasses);
      const proposalPublicRate = Helper.getProposalPublicRate(proposalsOrdered, id);
      const curvePath = this.getLineChartValues(proposalPublicRate);
      return (
        <PdfPages
          key={id}
          proposalClass={proposalClass}
          images={images}
          statistic={statistic}
          publicReviewArray={publicReviewArray}
          pageHeader={pageHeader}
          proposalData={proposalData}
          curvePath={curvePath}
          proposalPublicRate={proposalPublicRate}
        />
      );
    });
  };

  render() {
    const {data} = this.props;
    const getPdf = this.getPdf(data);
    return <Document id="test">{getPdf}</Document>;
  }
}

export default ExportPdf;
