import React, {Component} from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import {styled} from '@material-ui/styles';
import Button from '../inputs/Button';
import ManageReview from './ManageReview';
import {withTheme} from '@material-ui/styles';

const StyledH5 = styled('h5')(({theme}) => ({
  color: theme.modeColors.singleProposalSideNavText,
}));

class Review extends Component {
  state = {
    isModalOpen: false,
  };

  openModal = () => this.setState({isModalOpen: true});

  closeModal = () => this.setState({isModalOpen: false});

  render() {
    const {isModalOpen} = this.state;
    const {theme, title, proposal, edit, onSave, review} = this.props;
    return (
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <StyledH5 className="mb-0">{title}</StyledH5>
          {edit && (
            <Button style={{padding: 0}} onClick={this.openModal} size="sm">
              <span
                className={`d-flex align-items-center`}
                style={{color: theme.modeColors.textColor}}
              >
                <span className="mr-1">Manage </span>
                <SettingsIcon fontSize="small" />
              </span>
            </Button>
          )}

          {!edit && (
            <Button style={{padding: 0, minWidth: 0}} onClick={this.openModal} size="sm">
              Read <DescriptionIcon fontSize="small" />
            </Button>
          )}
        </div>
        <ManageReview
          title={title}
          close={this.closeModal}
          isOpen={isModalOpen}
          proposal={proposal}
          onSave={onSave}
          review={review}
          edit={edit}
        />
      </div>
    );
  }
}

export default withTheme(Review);
