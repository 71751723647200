import React, {Component} from 'react';
import _ from 'lodash';
import Button from '../inputs/Button';
import {withStyles} from '@material-ui/core/styles';
import {styled} from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import {Link} from 'react-router-dom';
import Rating from '../single-proposal/Rating';
import {PDFDownloadLink} from '@react-pdf/renderer';
import ExportPdf from './ExportPdf';
import Collapse from '@material-ui/core/Collapse';

const styles = theme => ({
  toggle: {
    width: '2rem',
    fontSize: '0.875rem',
  },
  no: {
    width: '3rem',
    fontWeight: 700,
    fontSize: '1.375rem',
    color: theme.modeColors.textColor,
  },
  pseudonym: {
    width: '5.5rem',
    fontWeight: 400,
    fontSize: '0.875rem',
    paddingTop: 5,
    paddingRight: 8,
    hyphens: 'auto',
  },
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.75rem',
  },
  classBox: {
    padding: '4px 0px',
    width: '6.5rem',
    textAlign: 'center',
    color: '#fff',
  },
  classHolder: {
    paddingTop: 5,
  },
  chipTag: {
    height: '1.5rem',
    backgroundColor: '#fff',
    padding: '0.25rem 0.75rem 0.125rem',
    marginLeft: '0.375rem',
    marginBottom: '0.25rem',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    fontWeight: 400,
    fontSize: '0.75rem',
  },
  imageContainer: {
    width: '10.5rem',
    marginRight: '1rem',
  },
  keysContainer: {
    width: '20rem',
  },
  expandItem: {
    backgroundColor: theme.modeColors.expandItemBackground,
  },
});

const StyledH5 = styled('h5')(({theme}) => ({
  color: theme.modeColors.singleProposalSideNavText,
}));

class ListItem extends Component {
  state = {
    expand: false,
    loadProposal: false,
  };

  toggleExpand = () => {
    this.setState({expand: !this.state.expand});
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.expandAll !== this.props.expandAll) {
      this.setState({expand: nextProps.expandAll});
    }
  }

  gitStatistic = () => {
    const {proposal} = this.props;
    if (proposal && proposal.excelData) {
      const {statistic} = proposal.excelData;
      return statistic
        ? _.map(statistic, (value, key) => {
            const statisticKey = Object.keys(value)[0];
            const statisticValue = value[Object.keys(value)[0]];
            const statisticValueArr = statisticValue.split(' ');
            if (statisticValueArr[0].indexOf(',') >= 0) {
              statisticValueArr[0] = statisticValueArr[0].replace(',', '.');
            }
            if (/^[0-9]+(\.)?[0-9]*$/.test(statisticValueArr[0])) {
              const floatVal = parseFloat(statisticValueArr[0]);
              if (floatVal && !Number.isInteger(floatVal)) {
                statisticValueArr[0] = Number(statisticValueArr[0]).toFixed(2);
              }
            }
            // Add space between thousands
            statisticValueArr[0] = statisticValueArr[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            return statisticValueArr ? (
              <div key={key} className="xs d-flex align-items-end pb-1">
                <span className="col mr-3">{statisticKey}:</span>
                <span className="col-1 text-right">{statisticValueArr.join(' ')}</span>
              </div>
            ) : null;
          })
        : null;
    }
    return null;
  };

  loadProposalPdf = () => {
    this.setState({loadProposal: true});
  };

  render() {
    const statistic = this.gitStatistic();
    const {expand, loadProposal} = this.state;
    const {
      classes,
      proposal,
      proposalClass,
      proposalPublicTags,
      proposalPrivateTags,
      proposalPublicNote,
      proposalPrivateNote,
      proposalPublicRate,
      proposalsOrdered,
      PublicClasses,
      id,
      users,
    } = this.props;
    const pdfData = {[id]: proposal};
    return (
      <div className={`border-bottom py-2 ${expand ? classes.expandItem : ''}`}>
        <div className="d-flex align-items-start">
          <div className={classes.toggle}>
            {!expand && <ExpandMore onClick={this.toggleExpand} />}
            {expand && <ExpandLess onClick={this.toggleExpand} />}
          </div>
          <div className={classes.no}>{proposal.number}</div>
          <div className={classes.pseudonym}>{proposal.name}</div>
          <div className={classes.class}>
            {proposalClass && (
              <div
                className={classes.classBox}
                style={{backgroundColor: proposalClass.colour}}
              >
                {proposalClass.class}
              </div>
            )}
            {!proposalClass && <div className={classes.classHolder}> No class </div>}
          </div>
          <div className="col sm">
            {proposalPublicTags.map((tag, i) => {
              return (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  classes={{
                    root: classes.chipTag,
                    label: 'xs p-0',
                  }}
                />
              );
            })}
          </div>
          <div
            className={!expand && proposalPublicNote ? 'ellipsis-text col xs' : 'col xs'}
          >
            {proposalPublicNote}
          </div>
          <div className="col sm">
            {proposalPrivateTags.map((tag, i) => {
              return (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  classes={{
                    root: classes.chipTag,
                    label: 'xs p-0',
                  }}
                />
              );
            })}
          </div>
          <div className="col xs">{proposalPrivateNote}</div>
        </div>
        <Collapse in={expand}>
          <div className="border-top mt-1 d-flex pt-1 pl-1">
            <div className={classes.imageContainer}>
              <Link className="normal-text" to={`/proposal/${id}`}>
                <img
                  className="img-fluid"
                  src={process.env.REACT_APP_URL + proposal.thumbnail_url}
                  alt={proposal.name}
                />
              </Link>
            </div>

            <div className={classes.keysContainer}>
              <StyledH5>Key figures of the entry</StyledH5>
              {statistic}
            </div>
            <div className="pl-4">
              <Rating title="Jury rating" proposal={proposal} rate={proposalPublicRate} />
            </div>
            <div className="ml-auto pr-1 pt-1">
              {!loadProposal && (
                <Button
                  size="sm"
                  variant="contained"
                  color="primary"
                  onClick={this.loadProposalPdf}
                >
                  Load the proposal card
                </Button>
              )}
              {loadProposal && (
                <PDFDownloadLink
                  document={
                    <ExportPdf
                      data={pdfData}
                      proposalsOrdered={proposalsOrdered}
                      PublicClasses={PublicClasses}
                      users={users}
                    />
                  }
                  fileName={proposal.name + '.pdf'}
                >
                  {({blob, url, loading, error}) =>
                    loading ? (
                      <Button size="sm" variant="contained" color="primary">
                        Loading document...
                      </Button>
                    ) : (
                      <Button size="sm" variant="contained" color="primary">
                        Export proposal card
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(ListItem);
