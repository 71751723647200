import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import {styled} from '@material-ui/styles';

const StyledText = styled('div')(({theme}) => ({
  color: theme.modeColors.textColor,
  minHeight: '40px',
  display: 'flex',
  alignItems: 'flex-end',
}));

const StyledH5 = styled('h5')(({theme}) => ({
  color: theme.modeColors.headingColor,
}));

class Elements extends Component {
  getElements = elements => {
    const {compareElements} = this.props;
    const activeBorder = id => {
      return compareElements && compareElements.includes(id);
    };
    if (elements) {
      if (this.props.single) {
        const types = _.uniq(_.map(elements, item => item.type));
        return types.map((item, i) => (
          <Fragment key={i}>
            {item === 'panel' && (
              <Grid container justify="flex-start" spacing={1}>
                {this.getElementsByTypes(item, elements)}
              </Grid>
            )}
          </Fragment>
        ));
      } else {
        return (
          <Grid container justify="flex-start" spacing={1}>
            {_.map(elements, (value, key) => {
              const elementProposal = this.getElementProposl(key);
              return (
                <Grid
                  key={key}
                  onClick={() =>
                    this.props.openSingleElement(value, key, elementProposal)
                  }
                  style={{
                    width: this.props.zoom * 100,
                    cursor: 'pointer',
                    border: activeBorder(key) ? '1px solid blue' : '',
                  }}
                  className="pb-1"
                  item
                >
                  <StyledH5 className="mb-0">
                    {elementProposal ? elementProposal[1].number : null}
                  </StyledH5>
                  <StyledText className="sm">{value.name}</StyledText>
                  <img
                    style={{width: '100%', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)'}}
                    className="pt-1"
                    src={process.env.REACT_APP_URL + value.thumbnail_url}
                    alt={value.name}
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      }
    }
    return null;
  };

  getElementsByTypes = (type, elements) => {
    const {zoom} = this.props;
    return Object.keys(elements).map((key, index) => {
      const value = elements[key];
      if (value.type === type) {
        return (
          <Grid
            key={key}
            onClick={() => this.props.openSingleElement(key, index)}
            style={{width: zoom === 6 ? '100%' : zoom * 150, cursor: 'pointer'}}
            className="pb-1"
            item
          >
            <StyledText>{value.name}</StyledText>
            <img
              style={{width: '100%', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)'}}
              className="pt-1"
              src={process.env.REACT_APP_URL + value.thumbnail_url}
              alt={value.name}
            />
          </Grid>
        );
      }
      return null;
    });
  };

  getElementProposl = val => {
    const {proposals} = this.props;
    const proposal = _.pickBy(proposals, (value, key) => {
      const keys = Object.keys(value.elements);
      return keys.includes(val);
    });

    if (Object.keys(proposal).length > 1) {
      return Object.entries(_.pickBy(proposal, (value, key) => value.number !== 1))[0];
    } else {
      return Object.entries(proposal)[0];
    }
  };

  render() {
    const {elements} = this.props;
    const getElements = elements ? this.getElements(elements) : null;
    return <div>{getElements}</div>;
  }
}

const mapStateToProps = state => {
  return {
    proposals: state.proposals.data,
  };
};

export default connect(
  mapStateToProps,
  null
)(Elements);
