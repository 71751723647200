import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Text from './Text';
import Button from './Button';
import {useTheme} from '@material-ui/styles';

const styles = theme => ({
  listHeader: {
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 8px 8px',
    backgroundColor: theme.modeColors.inputBackground,
  },
  listFooter: {
    position: 'absolute',
    bottom: 0,
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px 8px 8px',
    backgroundColor: theme.modeColors.inputBackground,
    borderTop: '1px solid #e0e0e0',
    width: '100%',
  },
  menuItem: {
    margin: '4px 8px',
    padding: '0px 8px',
    minHeight: 24,
    backgroundColor: theme.modeColors.inputBackground,
  },
  checkbox: {
    padding: 0,
    paddingRight: 8,
  },
});

const MultipleSelect = ({
  placeholder,
  options,
  values,
  onChange,
  selectAll,
  clearAll,
  apply,
  classes,
}) => {
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();

  const MenuProps = {
    MenuListProps: {
      style: {
        position: 'relative',
        overflow: 'auto',
        paddingBottom: 50,
        backgroundColor: theme.modeColors.inputBackground,
      },
    },
  };

  const applyChange = () => {
    apply();
    handleClose();
  };

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <Select
      multiple
      displayEmpty
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={values}
      onChange={onChange}
      MenuProps={MenuProps}
      input={<Text active={values.length} id="select-multiple-checkbox" size="md" />}
      renderValue={selected => {
        if (selected.length === 0) {
          return <span>{placeholder}</span>;
        }

        return selected.join(', ');
      }}
    >
      <MenuItem in={open ? 'true' : 'false'} value="none" className={classes.listHeader}>
        <Button onClick={() => selectAll()} size="md" color="primary">
          Select all
        </Button>
        <Button onClick={() => clearAll()} size="md">
          Clear all
        </Button>
      </MenuItem>
      {/* <Divider /> */}
      {options.map((option, i) => (
        <MenuItem
          disableGutters={true}
          key={i}
          value={option.name}
          classes={{
            root: classes.menuItem,
          }}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={values.indexOf(option.name) > -1}
            classes={{
              root: classes.checkbox,
            }}
            color="default"
          />

          <div className="d-flex full-width align-items-center">
            <div className="sm">{option.name} </div>
            <div className="d-flex ml-auto align-items-center">
              <div className="sm">{option.amount} </div>
              {option.color && (
                <div
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: option.color,
                    marginLeft: '8px',
                  }}
                />
              )}
            </div>
          </div>
        </MenuItem>
      ))}
      <MenuItem value="none" className={classes.listFooter}>
        <p className="sm pl-1 m-0">Selected: {values.length}</p>
        <Button variant="outlined" color="primary" onClick={applyChange} size="sm">
          Apply
        </Button>
      </MenuItem>
    </Select>
  );
};

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
};

export default withStyles(styles)(MultipleSelect);
