import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {filterProposals} from '../../redux/actions/proposalsActions';
import {firestoreConnect} from 'react-redux-firebase';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import * as searchHelper from '../../util/searchHelper';
import MultipleSelect from '../../inputs/MultipleSelect';

class PublicTags extends Component {
  state = {
    name: [],
  };

  componentDidMount() {
    const search = searchHelper.getSearchQuery(this.props.history.location.search);
    if (search.public_tags) {
      this.setState({name: search.public_tags.split(',')});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSearch = searchHelper.getSearchQuery(nextProps.history.location.search);
    const oldSearch = this.state.name.join(',');

    if (newSearch.public_tags !== oldSearch) {
      this.setState({
        name: newSearch.public_tags ? newSearch.public_tags.split(',') : [],
      });
    }
  }

  handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] !== 'none') {
      this.setState({name: value});
    }
  };

  selectAll = publicTags => {
    const values = publicTags.map(publicTag => publicTag.name);
    this.setState({name: values});
  };

  clearAll = () => {
    this.setState({name: []});
  };

  applyFilters = () => {
    const {name} = this.state;
    const {filterProposals, history} = this.props;
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'public_tags',
        name
      ),
    });
    filterProposals(history.location.search);
  };

  PublicTags = () => {
    const {PublicTags, proposals} = this.props;
    const tags = PublicTags
      ? PublicTags.map(val => {
          const proposalsHaveTags = _.filter(proposals, proposal => proposal.tags);
          const proposalsTags = _.map(proposalsHaveTags, 'tags');
          const allTags = [].concat.apply([], proposalsTags);
          const amount = _.filter(allTags, ['id', val.id]);
          return {
            name: val.name,
            amount: amount.length,
          };
        })
      : [];
    return tags;
  };

  render() {
    const PublicTags = this.PublicTags();
    return (
      <div id="filter-public-tags">
        <MultipleSelect
          options={PublicTags}
          values={this.state.name}
          onChange={this.handleChange}
          selectAll={() => this.selectAll(PublicTags)}
          clearAll={this.clearAll}
          placeholder="Public tags"
          apply={this.applyFilters}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    PublicTags: state.firestore.ordered.PublicTags,
    proposals: state.firestore.ordered.proposals,
  };
};

export default compose(
  connect(
    mapStateToProps,
    {filterProposals}
  ),
  firestoreConnect([{collection: 'PublicTags'}, {collection: 'proposals'}])
)(withRouter(PublicTags));
