import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {filterProposals} from '../../redux/actions/proposalsActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import * as searchHelper from '../../util/searchHelper';

const styles = theme => ({
  root: {
    color: theme.modeColors.checkbox,
  },
  label: {
    fontFamily: 'HarmoniaSansStd',
    paddingTop: 4,
    color: theme.modeColors.checkboxLabel,
  },
});

class HasNotes extends React.Component {
  state = {
    checkedF: false,
  };

  componentDidMount() {
    const search = searchHelper.getSearchQuery(this.props.history.location.search);
    if (search.has_notes) {
      this.setState({checkedF: !!search.has_notes});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSearch = searchHelper.getSearchQuery(nextProps.history.location.search);
    const oldSearch = this.state.checkedF;

    if (newSearch.has_notes !== oldSearch) {
      this.setState({checkedF: newSearch.has_notes ? !!newSearch.has_notes : false});
    }
  }

  handleChange = name => event => {
    const {filterProposals, history} = this.props;
    this.setState({[name]: event.target.checked});
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'has_notes',
        event.target.checked
      ),
    });
    filterProposals(history.location.search);
  };

  render() {
    const {classes} = this.props;
    return (
      <FormControlLabel
        id="filter-has-notes"
        control={
          <Checkbox
            className={classes.root}
            checked={this.state.checkedF}
            onChange={this.handleChange('checkedF')}
            value="checkedF"
            color="default"
          />
        }
        label="Has Notes"
        classes={{
          label: classes.label,
        }}
      />
    );
  }
}

export default withRouter(
  connect(
    null,
    {filterProposals}
  )(withStyles(styles)(HasNotes))
);
