import React, {useState} from 'react';
import _ from 'lodash';
import {withStyles} from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Button from '../inputs/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  compareBar: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '12px 32px',
    boxSizing: 'border-box',
    position: 'fixed',
    top: '40px',
    zIndex: 100000,
    width: '100%',
  },
});

const CompareBar = ({
  closeComparing,
  compareElements,
  removeFromCompare,
  goToComparing,
  classes,
}) => {
  const [expandCompare, setExpandCompare] = useState(true);
  return (
    <div className="position-relative">
      <div className="py-2"></div>
      <div className={classes.compareBar}>
        <div>
          <div className="d-flex align-items-center">
            {expandCompare && (
              <ExpandMore onClick={() => setExpandCompare(!expandCompare)} />
            )}
            {!expandCompare && (
              <ExpandLess onClick={() => setExpandCompare(!expandCompare)} />
            )}
            <h5 className="pl-1 mb-0">Compare elements</h5>
          </div>
          {expandCompare && (
            <div className="sm pl-3">Add up to three elements to compare.</div>
          )}
        </div>
        {expandCompare && (
          <div className="d-flex align-items-end">
            {_.map(compareElements, (value, key) => (
              <div key={key} style={{width: '180px', padding: '0 10px'}}>
                <div className="sm">{value.name}</div>
                <img
                  style={{maxWidth: '100%', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)'}}
                  className="pt-1"
                  src={process.env.REACT_APP_URL + value.thumbnail_url}
                  alt={value.name}
                />
                <IconButton
                  size="small"
                  aria-label="Delete"
                  color="inherit"
                  className="delete-element"
                  onClick={() => removeFromCompare(key)}
                >
                  <CloseIcon fontSize="small" color="inherit" />
                </IconButton>
              </div>
            ))}
          </div>
        )}
        {expandCompare && (
          <div className="d-flex">
            <Button
              size="md"
              variant="outlined"
              onClick={() => setExpandCompare(false)}
              color="primary"
            >
              Close
            </Button>
            <Button
              onClick={goToComparing}
              size="md"
              style={{marginLeft: 16}}
              disabled={!Object.keys(compareElements).length}
              variant="contained"
              color="primary"
            >
              Compare elements
            </Button>
            <Button
              size="md"
              onClick={closeComparing}
              style={{
                position: 'absolute',
                bottom: '-40px',
                right: '32px',
                backgroundColor: '#fff',
              }}
              variant="outlined"
              color="primary"
            >
              Close selection mode
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CompareBar);
