import React, {Component} from 'react';
import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';
import {addToDataBase} from '../redux/actions/dataBase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Editor} from 'react-draft-wysiwyg';
import {withStyles} from '@material-ui/core/styles';

import Button from '../inputs/Button';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = theme => ({
  root: {
    padding: '32px',
    margin: 0,
  },
  tab: {
    fontSize: '1rem',
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 270,
    minHeight: 32,
    marginBottom: 8,
    backgroundColor: theme.modeColors.tabBackground,
  },
  tabLabel: {
    padding: '4px 16px',
    alignItems: 'start',
  },

  selected: {
    backgroundColor: theme.modeColors.selectedTabBackground,
    fontWeight: 700,
  },
  tabContainer: {
    marginLeft: 16,
    padding: 32,
    backgroundColor: theme.modeColors.navBackground,
    flex: 1,
  },
  textModeColors: {
    color: theme.modeColors.proposalTitle,
  },
});

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column',
  },
  indicator: {
    display: 'none',
  },
}))(Tabs);

class CompetitionInfo extends Component {
  state = {
    activeIndex: 0,
    editorDetails: EditorState.createEmpty(),
    editorMaterials: EditorState.createEmpty(),
    editorRules: EditorState.createEmpty(),
    judgingDocs: EditorState.createEmpty(),
    editCompetitionInfo: false,
  };

  handleChange = (_, activeIndex) => this.setState({activeIndex});

  onEditorDetailsChange = editorState => {
    this.setState({editorDetails: editorState});
  };

  onEditorMaterialsChange = editorState => {
    this.setState({editorMaterials: editorState});
  };

  onEditorRulesChange = editorState => {
    this.setState({editorRules: editorState});
  };

  onJudgingDocsChange = editorState => {
    this.setState({judgingDocs: editorState});
  };

  editCompetition = () => this.setState({editCompetitionInfo: true});
  closeEditCompetiton = () => this.setState({editCompetitionInfo: false});

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {generalInfo} = nextProps;
    let editorDetails, editorMaterials, editorRules, judgingDocs;

    // Info - Competition details tab
    if (generalInfo && generalInfo.htmlDetails) {
      const contentBlockDetails = htmlToDraft(generalInfo.htmlDetails);
      const contentStateDetails = ContentState.createFromBlockArray(
        contentBlockDetails.contentBlocks
      );
      editorDetails = EditorState.createWithContent(contentStateDetails);
    } else {
      editorDetails = EditorState.createEmpty();
    }

    // Info - Competition rules tab
    if (generalInfo && generalInfo.htmlRules) {
      const contentBlockRules = htmlToDraft(generalInfo.htmlRules);
      const contentStateRules = ContentState.createFromBlockArray(
        contentBlockRules.contentBlocks
      );
      editorRules = EditorState.createWithContent(contentStateRules);
    } else {
      editorRules = EditorState.createEmpty();
    }

    // Info - Material for competitors tab
    if (generalInfo && generalInfo.htmlMaterials) {
      const contentBlockMaterials = htmlToDraft(generalInfo.htmlMaterials);
      const contentStateMaterials = ContentState.createFromBlockArray(
        contentBlockMaterials.contentBlocks
      );
      editorMaterials = EditorState.createWithContent(contentStateMaterials);
    } else {
      editorMaterials = EditorState.createEmpty();
    }

    // Info - Judging documents tab
    if (generalInfo && generalInfo.htmlJudgingDocs) {
      const contentBlockMaterials = htmlToDraft(generalInfo.htmlJudgingDocs);
      const contentStateMaterials = ContentState.createFromBlockArray(
        contentBlockMaterials.contentBlocks
      );
      judgingDocs = EditorState.createWithContent(contentStateMaterials);
    } else {
      judgingDocs = EditorState.createEmpty();
    }

    this.setState({editorDetails, editorRules, editorMaterials, judgingDocs});
  }

  saveEditCompetiton = () => {
    const {editorDetails, editorRules, editorMaterials, judgingDocs} = this.state;
    const {addToDataBase, enqueueSnackbar} = this.props;
    const htmlDetails = draftToHtml(convertToRaw(editorDetails.getCurrentContent()));
    const htmlRules = draftToHtml(convertToRaw(editorRules.getCurrentContent()));
    const htmlMaterials = draftToHtml(convertToRaw(editorMaterials.getCurrentContent()));
    const htmlJudgingDocs = draftToHtml(convertToRaw(judgingDocs.getCurrentContent()));
    const data = {htmlDetails, htmlRules, htmlMaterials, htmlJudgingDocs};
    addToDataBase('generalInfo', 'id-for-doc', data)
      .then(() => {
        enqueueSnackbar('Edit compotition information successfully', {
          variant: 'success',
        });
        this.closeEditCompetiton();
      })
      .catch(err => {
        enqueueSnackbar(err.message, {variant: 'error'});
        console.error(err.message);
      });
  };

  createMarkup(data) {
    return {__html: data};
  }

  render() {
    const {
      activeIndex,
      editorDetails,
      editorRules,
      editorMaterials,
      editCompetitionInfo,
      judgingDocs,
    } = this.state;
    const {classes, profile, generalInfo} = this.props;

    return (
      <div className={classes.root}>
        <h1 className={`mb-4 ${classes.textModeColors}`}>Competition information </h1>
        <div
          style={{
            display: 'flex',
          }}
        >
          <VerticalTabs value={activeIndex} onChange={this.handleChange}>
            <Tab
              label="Competition details "
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            />
            <Tab
              label="Competition rules"
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            />
            <Tab
              label="Materials for competitors "
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            />
            <Tab
              label="Judging document"
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel,
              }}
            />
          </VerticalTabs>

          {activeIndex === 0 && (
            <div className={classes.tabContainer}>
              <h3 className="mb-2 pb-1">Competition details </h3>
              {editCompetitionInfo && (
                <Editor
                  editorState={editorDetails}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class editor_container"
                  toolbar={{
                    list: {inDropdown: true},
                    textAlign: {inDropdown: true},
                    link: {inDropdown: true},
                  }}
                  onEditorStateChange={this.onEditorDetailsChange}
                />
              )}
              {!editCompetitionInfo && generalInfo && (
                <div
                  dangerouslySetInnerHTML={this.createMarkup(generalInfo.htmlDetails)}
                />
              )}
            </div>
          )}
          {activeIndex === 1 && (
            <div className={classes.tabContainer}>
              <h3 className="mb-2 pb-1">Competition rules</h3>
              {editCompetitionInfo && (
                <Editor
                  editorState={editorRules}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class editor_container"
                  toolbar={{
                    list: {inDropdown: true},
                    textAlign: {inDropdown: true},
                    link: {inDropdown: true},
                  }}
                  onEditorStateChange={this.onEditorRulesChange}
                />
              )}
              {!editCompetitionInfo && generalInfo && (
                <div dangerouslySetInnerHTML={this.createMarkup(generalInfo.htmlRules)} />
              )}
            </div>
          )}
          {activeIndex === 2 && (
            <div className={classes.tabContainer}>
              <h3 className="mb-2 pb-1">Materials for competitors </h3>
              {editCompetitionInfo && (
                <Editor
                  editorState={editorMaterials}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class editor_container"
                  toolbar={{
                    list: {inDropdown: true},
                    textAlign: {inDropdown: true},
                    link: {inDropdown: true},
                  }}
                  onEditorStateChange={this.onEditorMaterialsChange}
                />
              )}
              {!editCompetitionInfo && generalInfo && (
                <div
                  dangerouslySetInnerHTML={this.createMarkup(generalInfo.htmlMaterials)}
                />
              )}
            </div>
          )}
          {activeIndex === 3 && (
            <div className={classes.tabContainer}>
              <h3 className="mb-2 pb-1">Judging document</h3>
              {editCompetitionInfo && (
                <Editor
                  editorState={judgingDocs}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class editor_container"
                  toolbar={{
                    list: {inDropdown: true},
                    textAlign: {inDropdown: true},
                    link: {inDropdown: true},
                  }}
                  onEditorStateChange={this.onJudgingDocsChange}
                />
              )}
              {!editCompetitionInfo && generalInfo && (
                <div
                  dangerouslySetInnerHTML={this.createMarkup(generalInfo.htmlJudgingDocs)}
                />
              )}
            </div>
          )}
        </div>
        {profile.role !== 'judge' && (
          <div className="d-flex align-items-center justify-content-end mt-3">
            {!editCompetitionInfo && (
              <Button
                variant="contained"
                color="primary"
                size="md"
                onClick={this.editCompetition}
              >
                Edit
              </Button>
            )}
            {editCompetitionInfo && profile.role !== 'judge' && (
              <div className="d-flex align-items-center">
                <Button
                  variant="outlined"
                  color="primary"
                  size="md"
                  onClick={this.closeEditCompetiton}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{marginLeft: '8px'}}
                  color="primary"
                  size="md"
                  onClick={this.saveEditCompetiton}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    generalInfo: state.firestore.ordered.generalInfo
      ? state.firestore.ordered.generalInfo[0]
      : {},
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{collection: 'generalInfo'}])
)(withSnackbar(withStyles(styles)(CompetitionInfo)));
