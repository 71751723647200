import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {withStyles} from '@material-ui/core/styles';
import Button from '../inputs/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {addToDataBase, deleteFromDataBase} from '../redux/actions/dataBase';
import TextField from '../inputs/Text';
import CustomRadio from '../inputs/CustomRadio';
import {withSnackbar} from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import * as Helper from '../util/Helper.js';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    width: 432,
    backgroundColor: theme.modeColors.inputBackground,
  },
  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px',
  },
});

class ManageClasses extends React.Component {
  state = {
    chosenColor: '',
    nameOfCalss: '',
    openRemove: false,
    classData: {},
  };

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value, error: false});
  };

  createclass = (chosenColor, nameOfCalss, addToDataBase, enqueueSnackbar) => {
    Helper.createclass(chosenColor, nameOfCalss, addToDataBase, enqueueSnackbar);

    if (this.state.chosenColor && this.state.nameOfCalss) {
      this.resetValues();
    }
  };

  resetValues = () => {
    this.setState({
      chosenColor: '',
      nameOfCalss: '',
    });
  };

  handleCloseRemove = () => this.setState({openRemove: false});
  handleOpenRemove = c => this.setState({openRemove: true, classData: c});

  removeClass = () => {
    const id = this.state.classData.id;
    const {
      enqueueSnackbar,
      deleteFromDataBase,
      addToDataBase,
      proposalsData,
    } = this.props;
    Helper.removeClass(
      id,
      enqueueSnackbar,
      deleteFromDataBase,
      addToDataBase,
      proposalsData
    );
    this.handleCloseRemove();
  };

  render() {
    const {
      close,
      isOpen,
      classes,
      PublicClasses,
      proposal,
      proposalId,
      proposalClass,
      addToDataBase,
      enqueueSnackbar,
    } = this.props;
    const {chosenColor, nameOfCalss, openRemove} = this.state;
    return (
      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="md"
        classes={{paper: classes.dialogPaper}}
      >
        <div className="p-3">
          <h2>Manage classes </h2>
          <div className="border-bottom d-flex align-items-center pb-2">
            <h3 className="mb-0 pr-1">Proposal {proposal.number}:</h3>
            {proposalClass && (
              <div
                className="big-class-container "
                style={{backgroundColor: proposalClass.colour}}
              >
                {proposalClass.class}
                <IconButton
                  size="small"
                  aria-label="Delete"
                  color="inherit"
                  className="delete-class"
                  onClick={() =>
                    Helper.removeClassFromProposal(proposalId, addToDataBase)
                  }
                >
                  <CloseIcon fontSize="small" color="inherit" />
                </IconButton>
              </div>
            )}
          </div>

          <div className="pt-2 border-bottom">
            <h5 className="mb-0">Choose class for this proposal</h5>
            <List>
              {PublicClasses
                ? PublicClasses.map(publicClass => {
                    return (
                      <ListItem
                        onClick={() =>
                          Helper.addClassToProposal(
                            publicClass,
                            proposalId,
                            addToDataBase,
                            enqueueSnackbar
                          )
                        }
                        key={publicClass.id}
                        className="px-1"
                        button
                      >
                        <div
                          className="medium-class-container"
                          style={{backgroundColor: publicClass.colour}}
                        >
                          {publicClass.class}
                        </div>
                        <ListItemSecondaryAction>
                          <Tooltip
                            classes={{tooltip: classes.tooltip}}
                            title="Remove the class from every where"
                          >
                            <IconButton
                              onClick={() => this.handleOpenRemove(publicClass)}
                              size="small"
                              aria-label="Delete"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })
                : null}
            </List>
          </div>

          <div className="py-3">
            <h5 className="mb-2">Create a new class</h5>
            <div>
              <TextField
                value={nameOfCalss}
                onChange={this.handleChange}
                name="nameOfCalss"
                placeholder="Text"
                size="md"
              />
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div>
                  <div className="sm mb-1">Choose colour</div>
                  {[
                    '#999da0',
                    '#61be65',
                    '#f3e200',
                    '#ffa632',
                    '#ff4c4c',
                    '#3f51b5',
                    '#61a8be',
                    '#bc61be',
                    '#dedede',
                    '#a74c32',
                    '#b6a55c',
                    '#ffa8ad',
                  ].map((color, i) => {
                    return (
                      <CustomRadio
                        checked={color === chosenColor}
                        onChange={this.handleChange}
                        key={i}
                        name="chosenColor"
                        color={color}
                        value={color}
                      />
                    );
                  })}
                </div>

                <Button
                  style={{padding: '9px 12px 7px'}}
                  size="md"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    this.createclass(
                      chosenColor,
                      nameOfCalss,
                      addToDataBase,
                      enqueueSnackbar
                    )
                  }
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end pt-2">
            <Button
              style={{marginLeft: '16px'}}
              size="md"
              variant="contained"
              onClick={close}
              color="primary"
            >
              Done
            </Button>
          </div>
        </div>
        <RemoveClass
          open={openRemove}
          handleClose={this.handleClose}
          removeClass={this.removeClass}
          className={this.state.classData.class}
        />
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
  };
};

const mapStateToProps = state => {
  return {
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsData: state.firestore.data.proposals,
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{collection: 'PublicClasses'}])
)(withSnackbar(withStyles(styles)(ManageClasses)));

const RemoveClass = ({open, handleClose, removeClass, className}) => (
  <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Remove class.</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        That's will remove {className} from all proposals.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button size="md" variant="outlined" color="primary" onClick={handleClose}>
        Cancel
      </Button>
      <Button
        size="md"
        variant="contained"
        color="primary"
        onClick={() => {
          removeClass();
        }}
        autoFocus
      >
        remove
      </Button>
    </DialogActions>
  </Dialog>
);
