export const rating_fields = [
  {
    name: 'overall',
    label: 'Overall rating',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 15"><g fill="none" fill-rule="evenodd"><path fill="#353C43" fill-rule="nonzero" d="M13.833 0H2.167C1.25 0 .5.75.5 1.667v11.666C.5 14.25 1.25 15 2.167 15h11.666c.917 0 1.667-.75 1.667-1.667V1.667C15.5.75 14.75 0 13.833 0zM5.5 11.667H3.833V5.833H5.5v5.834zm3.333 0H7.167V3.333h1.666v8.334zm3.334 0H10.5V8.333h1.667v3.334z"/><path d="M-2-2.5h20v20H-2z"/></g></svg>',
  },
  {
    name: 'trafic_transport',
    label: 'Trafic and transport',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 17"><g fill="none" fill-rule="evenodd"><path fill="#353C43" fill-rule="nonzero" d="M11.833 12.617V5.583c0-2.325-2.175-2.833-5.008-2.908l.633-1.258h2.709V.167H1.833v1.25h3.959l-.634 1.266c-2.608.075-4.991.592-4.991 2.9v7.034c0 1.208.991 2.216 2.158 2.475L1 16.417v.416h1.858l1.667-1.666h3.142l1.666 1.666H11v-.416l-1.25-1.25h-.067c1.409 0 2.15-1.142 2.15-2.55zM6 13.917c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25c0 .691-.558 1.25-1.25 1.25zm4.167-3.75H1.833V6h8.334v4.167z"/><path d="M-4-1.5h20v20H-4z"/></g></svg>',
  },
  {
    name: 'construction_architecture',
    label: 'Construction and architecture',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21"><g fill="none" fill-rule="evenodd"><path  d="M0 .5h20v20H0z"/><path fill="#353C43" fill-rule="nonzero" d="M14.167 9.667V3H5.833v3.333H2.5V18h6.667v-3.333h1.666V18H17.5V9.667h-3.333zm-8.334 6.666H4.167v-1.666h1.666v1.666zm0-3.333H4.167v-1.667h1.666V13zm0-3.333H4.167V8h1.666v1.667zM9.167 13H7.5v-1.667h1.667V13zm0-3.333H7.5V8h1.667v1.667zm0-3.334H7.5V4.667h1.667v1.666zM12.5 13h-1.667v-1.667H12.5V13zm0-3.333h-1.667V8H12.5v1.667zm0-3.334h-1.667V4.667H12.5v1.666zm3.333 10h-1.666v-1.666h1.666v1.666zm0-3.333h-1.666v-1.667h1.666V13z"/></g></svg>',
  },
  {
    name: 'urban_outdoor_green_areas',
    label: 'Urban outdoor stages and urban green areas',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 17"><g fill="none" fill-rule="evenodd"><path d="M-1-1.5h20v20H-1z"/><path fill="#353C43" fill-rule="nonzero" d="M17.475 6.142A5.83 5.83 0 0 0 11.642.308a5.83 5.83 0 0 0-5.834 5.834c0 2.891 2.1 5.283 4.859 5.741v3.284H4v-2.5h.833V9.333A.836.836 0 0 0 4 8.5H1.5a.836.836 0 0 0-.833.833v3.334H1.5v4.166h13.333v-1.666h-2.5v-3.234a5.833 5.833 0 0 0 5.142-5.791zM2.75 7.667c.692 0 1.25-.559 1.25-1.25 0-.692-.558-1.25-1.25-1.25s-1.25.558-1.25 1.25c0 .691.558 1.25 1.25 1.25z"/></g></svg>',
  },
  {
    name: 'urban_culture_events_tourism',
    label: 'Urban culture events and tourism',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 17"><g fill="none" fill-rule="evenodd"><path d="M-1-1.5h20v20H-1z"/><g fill="#353C43" fill-rule="nonzero"><path d="M17.333 6L9 .167.667 6h7.5v10.833h1.666V6z"/><path d="M2.45 8.5l-1.633.308.683 3.642v4.383h1.667l.016-3.333h1.65v3.333H6.5v-5H3.083zM14.917 11.833H11.5v5h1.667V13.5h1.65l.016 3.333H16.5V12.45l.683-3.642L15.55 8.5z"/></g></g></svg>',
  },
  {
    name: 'housing_lifestyle',
    label: 'Housing and lifestyle',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 15"><g fill="none" fill-rule="evenodd"><path d="M-1-2.5h20v20H-1z"/><path fill="#353C43" fill-rule="nonzero" d="M14.833 5.25V.833h-2.5V3L9 0 .667 7.5h2.5v6.667h4.166v-5h3.334v5h4.166V7.5h2.5l-2.5-2.25zm-7.5.583c0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666H7.333z"/></g></svg>',
  },
  {
    name: 'business_industral_knowhow',
    label: 'Business, industral sector and knowhow',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 15"><g fill="none" fill-rule="evenodd"><path d="M-1-2.5h20v20H-1z"/><path fill="#353C43" fill-rule="nonzero" d="M3.167 4.167h1.666v6.666H3.167V4.167zm5.833 0H6.5A.836.836 0 0 0 5.667 5v5c0 .458.375.833.833.833H9A.836.836 0 0 0 9.833 10V5A.836.836 0 0 0 9 4.167zm-.833 5h-.834V5.833h.834v3.334zm5.833-5h-2.5a.836.836 0 0 0-.833.833v5c0 .458.375.833.833.833H14a.836.836 0 0 0 .833-.833V5A.836.836 0 0 0 14 4.167zm-.833 5h-.834V5.833h.834v3.334z"/><path d="M2.333 2.5h13.334v10H2.333z"/><path fill="#353C43" fill-rule="nonzero" d="M.667.833v13.334h16.666V.833H.667zM2.333 12.5v-10h13.334v10H2.333z"/></g></svg>',
  },
];

export const rating_fields_2 = {
  overall: {
    label: 'Overall rating',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 15"><g fill="none" fill-rule="evenodd"><path fill="#353C43" fill-rule="nonzero" d="M13.833 0H2.167C1.25 0 .5.75.5 1.667v11.666C.5 14.25 1.25 15 2.167 15h11.666c.917 0 1.667-.75 1.667-1.667V1.667C15.5.75 14.75 0 13.833 0zM5.5 11.667H3.833V5.833H5.5v5.834zm3.333 0H7.167V3.333h1.666v8.334zm3.334 0H10.5V8.333h1.667v3.334z"/><path d="M-2-2.5h20v20H-2z"/></g></svg>',
  },

  trafic_transport: {
    label: 'Trafic and transport',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 17"><g fill="none" fill-rule="evenodd"><path fill="#353C43" fill-rule="nonzero" d="M11.833 12.617V5.583c0-2.325-2.175-2.833-5.008-2.908l.633-1.258h2.709V.167H1.833v1.25h3.959l-.634 1.266c-2.608.075-4.991.592-4.991 2.9v7.034c0 1.208.991 2.216 2.158 2.475L1 16.417v.416h1.858l1.667-1.666h3.142l1.666 1.666H11v-.416l-1.25-1.25h-.067c1.409 0 2.15-1.142 2.15-2.55zM6 13.917c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25s1.25.558 1.25 1.25c0 .691-.558 1.25-1.25 1.25zm4.167-3.75H1.833V6h8.334v4.167z"/><path d="M-4-1.5h20v20H-4z"/></g></svg>',
  },
  construction_architecture: {
    label: 'Construction and architecture',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21"><g fill="none" fill-rule="evenodd"><path  d="M0 .5h20v20H0z"/><path fill="#353C43" fill-rule="nonzero" d="M14.167 9.667V3H5.833v3.333H2.5V18h6.667v-3.333h1.666V18H17.5V9.667h-3.333zm-8.334 6.666H4.167v-1.666h1.666v1.666zm0-3.333H4.167v-1.667h1.666V13zm0-3.333H4.167V8h1.666v1.667zM9.167 13H7.5v-1.667h1.667V13zm0-3.333H7.5V8h1.667v1.667zm0-3.334H7.5V4.667h1.667v1.666zM12.5 13h-1.667v-1.667H12.5V13zm0-3.333h-1.667V8H12.5v1.667zm0-3.334h-1.667V4.667H12.5v1.666zm3.333 10h-1.666v-1.666h1.666v1.666zm0-3.333h-1.666v-1.667h1.666V13z"/></g></svg>',
  },
  urban_outdoor_green_areas: {
    label: 'Urban outdoor stages and urban green areas',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 17"><g fill="none" fill-rule="evenodd"><path d="M-1-1.5h20v20H-1z"/><path fill="#353C43" fill-rule="nonzero" d="M17.475 6.142A5.83 5.83 0 0 0 11.642.308a5.83 5.83 0 0 0-5.834 5.834c0 2.891 2.1 5.283 4.859 5.741v3.284H4v-2.5h.833V9.333A.836.836 0 0 0 4 8.5H1.5a.836.836 0 0 0-.833.833v3.334H1.5v4.166h13.333v-1.666h-2.5v-3.234a5.833 5.833 0 0 0 5.142-5.791zM2.75 7.667c.692 0 1.25-.559 1.25-1.25 0-.692-.558-1.25-1.25-1.25s-1.25.558-1.25 1.25c0 .691.558 1.25 1.25 1.25z"/></g></svg>',
  },
  urban_culture_events_tourism: {
    label: 'Urban culture events and tourism',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 17"><g fill="none" fill-rule="evenodd"><path d="M-1-1.5h20v20H-1z"/><g fill="#353C43" fill-rule="nonzero"><path d="M17.333 6L9 .167.667 6h7.5v10.833h1.666V6z"/><path d="M2.45 8.5l-1.633.308.683 3.642v4.383h1.667l.016-3.333h1.65v3.333H6.5v-5H3.083zM14.917 11.833H11.5v5h1.667V13.5h1.65l.016 3.333H16.5V12.45l.683-3.642L15.55 8.5z"/></g></g></svg>',
  },
  housing_lifestyle: {
    label: 'Housing and lifestyle',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 15"><g fill="none" fill-rule="evenodd"><path d="M-1-2.5h20v20H-1z"/><path fill="#353C43" fill-rule="nonzero" d="M14.833 5.25V.833h-2.5V3L9 0 .667 7.5h2.5v6.667h4.166v-5h3.334v5h4.166V7.5h2.5l-2.5-2.25zm-7.5.583c0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666H7.333z"/></g></svg>',
  },
  business_industral_knowhow: {
    label: 'Business, industral sector and knowhow',
    svg:
      '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 15"><g fill="none" fill-rule="evenodd"><path d="M-1-2.5h20v20H-1z"/><path fill="#353C43" fill-rule="nonzero" d="M3.167 4.167h1.666v6.666H3.167V4.167zm5.833 0H6.5A.836.836 0 0 0 5.667 5v5c0 .458.375.833.833.833H9A.836.836 0 0 0 9.833 10V5A.836.836 0 0 0 9 4.167zm-.833 5h-.834V5.833h.834v3.334zm5.833-5h-2.5a.836.836 0 0 0-.833.833v5c0 .458.375.833.833.833H14a.836.836 0 0 0 .833-.833V5A.836.836 0 0 0 14 4.167zm-.833 5h-.834V5.833h.834v3.334z"/><path d="M2.333 2.5h13.334v10H2.333z"/><path fill="#353C43" fill-rule="nonzero" d="M.667.833v13.334h16.666V.833H.667zM2.333 12.5v-10h13.334v10H2.333z"/></g></svg>',
  },
};
