import qs from 'querystringify';
import _ from 'lodash';

export const getSearchQuery = search => {
  return qs.parse(search);
};

export const updateSearchQuery = (search, query, val) => {
  val = val ? val : null;
  if (!val || val.length === 0) {
    const searchQuery = qs.parse(search);
    delete searchQuery[query];
    return qs.stringify({...searchQuery}, true);
  } else {
    if (query === 'search') {
      return qs.stringify({[query]: val}, true);
    } else {
      const searchQuery = qs.parse(search);
      delete searchQuery['search'];
      return qs.stringify({...searchQuery, [query]: val}, true);
    }
  }
};

export const searchData = (search, initData) => {
  if (search.includes('-')) {
    const searchArrang = search.split('-'),
      firstNumber = parseInt(searchArrang[0].replace(' ', ''), 10),
      secondNumber = parseInt(searchArrang[1].replace(' ', ''), 10),
      biggerNumber = firstNumber > secondNumber ? firstNumber : secondNumber,
      smallNumber = firstNumber < secondNumber ? firstNumber : secondNumber;
    const proposals = _.pickBy(
      initData,
      (val, key) => val.number >= smallNumber && val.number <= biggerNumber
    );
    const allElements = Object.assign({}, ..._.map(initData, val => val.elements));
    const elements = _.pickBy(
      allElements,
      (val, key) => val.number >= smallNumber && val.number <= biggerNumber
    );
    return {proposals, elements};
  } else {
    search = search.toLowerCase();
    const proposals = _.pickBy(
      initData,
      (val, key) =>
        _.includes(val.name.toLowerCase(), search) ||
        _.includes(val.number.toString(), search)
    );
    const allElements = Object.assign({}, ..._.map(initData, val => val.elements));
    const elements = _.pickBy(allElements, (val, key) =>
      _.includes(val.name.toLowerCase(), search)
    );
    return {proposals, elements};
  }
};
