import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import {filterProposals} from '../../redux/actions/proposalsActions';
import MultipleSelect from '../../inputs/MultipleSelect';
import {withRouter} from 'react-router-dom';
import * as searchHelper from '../../util/searchHelper';

class Classes extends Component {
  state = {
    name: [],
  };

  componentDidMount() {
    const search = searchHelper.getSearchQuery(this.props.history.location.search);
    if (search.classes) {
      this.setState({name: search.classes.split(',')});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newSearch = searchHelper.getSearchQuery(nextProps.history.location.search);
    const oldSearch = this.state.name.join(',');

    if (newSearch.classes !== oldSearch) {
      this.setState({name: newSearch.classes ? newSearch.classes.split(',') : []});
    }
  }

  handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] !== 'none') {
      this.setState({name: value});
    }
  };

  applyFilters = () => {
    const {name} = this.state;
    const {filterProposals, history} = this.props;
    history.push({
      search: searchHelper.updateSearchQuery(history.location.search, 'classes', name),
    });
    filterProposals(history.location.search);
  };

  selectAll = classes => {
    const values = classes.map(classe => classe.name);
    this.setState({name: values});
  };

  clearAll = () => {
    this.setState({name: []});
  };

  getPublicClasses = () => {
    const {PublicClasses, proposals} = this.props;
    const classes = PublicClasses
      ? PublicClasses.map(val => {
          const amount = _.filter(proposals, proposal => {
            const classID = _.get(proposal, 'class.id');
            return classID === val.id;
          });
          return {
            name: val.class,
            amount: amount.length,
            color: val.colour,
            id: val.id,
          };
        })
      : [];
    return classes;
  };

  render() {
    const PublicClasses = this.getPublicClasses();
    return (
      <div id="filter-class">
        <MultipleSelect
          options={PublicClasses}
          values={this.state.name}
          onChange={this.handleChange}
          selectAll={() => this.selectAll(PublicClasses)}
          clearAll={this.clearAll}
          placeholder="Classes"
          apply={this.applyFilters}
          id="filter-class"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposals: state.firestore.ordered.proposals,
  };
};

export default compose(
  connect(
    mapStateToProps,
    {filterProposals}
  ),
  firestoreConnect([{collection: 'PublicClasses'}, {collection: 'proposals'}])
)(withRouter(Classes));
